import React, { useRef, useEffect } from 'react';
import { ABOUT } from '../constants/text-constants.js';
import { Palette } from '../styles/styles.js';
import { Card } from '../components';
import Background from '../styles/images/bus_second.png';
import BackgroundSmall from '../styles/images/bus_second_small.png';
import styled from 'styled-components';
import { Element } from 'react-scroll'
import { useMediaQuery } from 'react-responsive'

const titleStyles = {
  color: Palette.dark_blue
};



export const About = () => {
  const isMobile = useMediaQuery({ maxWidth: 810})
  const imageCardRef = useRef();
  
  useEffect(() => {
  }, []);

  const bodyStyles = {
    color: Palette.black,
    lineHeight: '20px',
    width: isMobile ? '100%' : '600px'
  }
 
  const Image = styled.img`
    transform: scaleX(-1);
    height: ${props => !isMobile ? 'auto' : `250px`}
  `; 

  let imgSource = isMobile ? BackgroundSmall : Background;

  return (
    <div className="mt4 flex flex-column justify-center items-center">
      <Element name="about">
      <div className={`b pa3 mb1 ${isMobile ? 'f2' : 'f1'}`} style={titleStyles}>
        {ABOUT.TITLE}
      </div>
      <div className="flex flex-row flex-wrap pa333t0 justify-center items-center mb5">
        <div className="ph3 f5 flex helvetica fw3 pb3" style={bodyStyles}>
          {ABOUT.BODY_LEFT}
        </div>
        <div className="f5 flex helvetica fw3 pb3 ph3" style={bodyStyles}>
          {ABOUT.BODY_RIGHT}
        </div>
      </div>
      </Element>
      <Element name="services">
        <div  className={`relative flex items-center justify-${isMobile ? 'center' : 'start'} mb5`}>
          <Image src={imgSource}/>
          <div ref={imageCardRef} className="absolute mh3">
            <Card isLight>
              <div className={`b ${isMobile ? 'f2' : 'f1'}`}>{ABOUT.SERVICES}</div>
              <div className={`pt2 helvetica fw3 ${isMobile ? 'f5' : 'f4'}`}>{ABOUT.SERVICES_BODY}</div>
            </Card>
          </div>
        </div>
      </Element>
    </div>
  );
};

export default React.memo(About);
