import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useMediaQuery } from 'react-responsive'

const importAll = (r) => {
  let keys = r.keys().map(r => r);
  let vals = r.keys().map(r);
  return vals.map((val, index) => {
    return {
      name: keys[index].replace('./', '').replace('.png', '').replace(':', ''),
      src: val
    };
  });
}

const initImages = importAll(require.context('../styles/images/client_logo/', false, /\.(png|jpe?g|svg)$/));

const Container = styled.div`
  transiton: all .4s;
  background-color: white;
  box-shadow: 4px 8px 36px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-wrap: none;
  flex-direction: row;
  width: 100vw;
  overflow: hidden;
`;


const Clients = props => {
  const isMobile = useMediaQuery({ maxDeviceWidth: 768 })
  let containerRef = useRef();
  let imageContainerRef = useRef();
  let [containerWidth, setContainerWidth] = useState(0);
  let [images, setImages] = useState([]);

  useEffect(() => {
    setContainerWidth((initImages.length) * 200);
  }, [images])

  useEffect(() => {
    let containerWidth = imageContainerRef.current.offsetWidth;
    let numToadd = Math.ceil(containerWidth / 200);
    let additionalImages = initImages.slice(0, numToadd);
    let newImages = [...initImages, ...additionalImages];
    setImages(newImages);

    setContainerWidth(initImages) ;
  }, []);

  const moveSlideshow = keyframes`
    100% {
     transform: translateX(-${containerWidth}px)
    }
  `; 

  const ImageContainer = styled.div`
    animation: ${moveSlideshow} 60s linear infinite;
    display: flex;
    flex-wrap: none;
    flex-direction: row;
    align-items: center;
    &:hover {
      animation-play-state: paused;
    }
  `;

  const ImageWrapper = styled.div`
    width: 200px;
    maxHeight: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <>
      <div className={`fw3 helvetica f5 mt5 w-100 mb4 ${isMobile ? 'ph3' : 'ph5'}`}>
        Southern Coach Parts Company serves a wide range of notable clients, including (but not limited to):
      </div>
      <Container ref={imageContainerRef} containerId="containerElement" className="pv5 items-center">
        <ImageContainer ref={containerRef}>
          {images.map((imgSrc, index) => 
          <ImageWrapper href={`http://${imgSrc.name}`} key={`${imgSrc}${index}`} className="">
            <img alt="" src={imgSrc.src}/>
          </ImageWrapper>
          )}
        </ImageContainer>
      </Container>
    </>
  );
};

export default Clients;
