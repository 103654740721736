export const Palette = {
  white: 'white',
  black: '#293141',
  grey: '#706C61',
  light_grey: '#F4F6F7',
  dark_blue: '#232B3A',
  blue: '#0094FF',
  deep_blue: '#0076CC',
  light_blue: '#33A9FF'
}
