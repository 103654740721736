import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card } from '../components';
import { HOME } from '../constants/text-constants.js';
import Background from '../styles/images/bus_main_small_desktop.png';
import BackgroundSmall from '../styles/images/bus_main_small.png';
import { useMediaQuery } from 'react-responsive'

const Image = styled.img`
  display: block;
  top: 0;
  width: 100vw;
  min-wdith: 100vw;
  mask-image: linear-gradient(rgba(220, 230, 248, 1) 0%, 90%, rgba(220, 230, 248, 0));
`; 

export const Home = () => {
  const isMobile = useMediaQuery({ maxWidth: 610 })
  let [width, setWidth] = useState(0);
  let imgRef = useRef();

  useEffect(() => {
    _resizeImg();
  },[]);

  const _resizeImg = () => {
    setWidth(imgRef.current.offsetWidth);
  };
  
  const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(rgba(220, 230, 248, 1) 0%, 95%, rgba(220, 230, 248, 0));
    max-height: 100vh;
    min-height: ${() => isMobile ? '0' : 300 + (width / 2.8)}px;
    min-width: 100vw;
    padding-bottom: ${!isMobile && '10vh'};
  `;

  const CardContainer = styled.div`
    position: ${isMobile ? '': 'absolute'};
    width: 100%;
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 100;
    top: ${() => isMobile ? '0' : width / 2.8}px;
    padding: ${() => isMobile ? '1rem' : '3rem'};
  `;


  const titleClass = `${isMobile ? 'f2': 'f1'} b`;
  const bodyClass = `helvetica fw3 ${isMobile ? 'f5' : 'f4'}`;

  let imgSource = isMobile ? BackgroundSmall : Background;

  window.onresize = _resizeImg;

  return (
    <Container>
      <Image ref={imgRef} src={imgSource} alt={Background} />
      <CardContainer>
        <Card>
          <div className={titleClass}>{HOME.SOUTHERN_COACH}</div>
          <div className={titleClass}>{HOME.PARTS_COMPANY}</div>
          <div className={`${bodyClass} pt3`}>{HOME.SOUTHERN_COACH_CARD_BODY_1}</div>
          <div className={bodyClass}>{HOME.SOUTHERN_COACH_CARD_BODY_2}</div>
          <div className={bodyClass}>{HOME.SOUTHERN_COACH_CARD_BODY_3}</div>
          <div className={`${bodyClass} pt3`}>{HOME.SCROLL_FOR_MORE}</div>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default React.memo(Home);
