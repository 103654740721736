import React from 'react';
import { Palette } from '../styles/styles.js';
import styled from 'styled-components';


export const Button = props => {
  const Button = styled.div`
    transition: all .4s;
    background-color: ${Palette.blue};
    &:hover {
      background-color: ${Palette.light_blue};
    };
    &:active {
      background-color: ${Palette.deep_blue};
    }
  `;

  return (
    <div className="flex align-center justify-end pointer">
      <Button onClick={props.onClick} className="white helvetica ph3 pv3 fw6 dib br2">
        {props.label}
      </Button>
    </div>
  );
};

export default Button;
