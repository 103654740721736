import React, { useState } from 'react';
import { CONTACT } from '../constants/text-constants.js';
import { InputField, MessageInput, Button, Popup } from '../components';
import { useMediaQuery } from 'react-responsive'

export const Contact = () => {
  const isMobile = useMediaQuery({ maxDeviceWidth: 768 })

  let [email, setEmail] = useState('');
  let [name, setName] = useState('');
  let [subject, setSubject] = useState('');
  let [message, setMessage] = useState('');
  let [showPopup, setShowPopup] = useState(false);

  let [isLoading, setIsLoading] = useState(true);
  let [isSucess, setIsSucess] = useState(false);
  let [isFail, setIsFail] = useState(false);

  let [isEmailValid, setIsEmailValid] = useState(true);
  let [isNameValid, setIsNameValid] = useState(true);
  let [isSubjectValid, setIsSubjectValid] = useState(true);
  let [isMessageValid, setIsMessageValid] = useState(true);

  let containerClass = `pa4 dib w-100 ${isMobile && 'ph0'}`; 
  let wrapperClass = `pa3 mt4 pv3 flex flex-row ${isMobile && 'flex-wrap'}`

  const _setEmail = event => {
    setEmail(event.target.value);
  }

  const _setName = event => {
    setName(event.target.value);
  }

  const _setSubject = event => {
    setSubject(event.target.value);
  }

  const _setMessage= event => {
    setMessage(event.target.value);
  }

  const _areInputsValid = () => {
    let isValid = true;

    if (!name.trim().length) {
      setIsNameValid(false); 
      isValid = false;
    } else setIsNameValid(true); 

    if (!subject.trim().length) {
      setIsSubjectValid(false); 
      isValid = false;
    } else setIsSubjectValid(true); 

    if (!message.trim().length) {
      setIsMessageValid(false); 
      isValid = false;
    } else setIsMessageValid(true); 

    if (!email.trim().length || !email.includes('@') || !email.includes('.')) {
      setIsEmailValid(false); 
      isValid = false;
    } else setIsEmailValid(true); 

    return isValid;
  };

  const _sendMessage = async () => {
    if (!_areInputsValid()) return;
    setIsLoading(true);
    setIsSucess(false);
    setIsFail(false);
    setShowPopup(true);

    try {
      await fetch('https://southern-coach-api.now.sh/contact-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          subject,
          message
        })
      }); 
      setIsLoading(false);
      setIsSucess(true);
    } catch (e) {
      setIsLoading(false);
      setIsFail(true);
    } finally {
      setEmail('');
      setName('');
      setMessage('');
      setSubject('');
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    };
  };

  return (
    <>
      <div className={wrapperClass} >
        <div className={containerClass} >
          <div className="f1 black b pb3" >
            {CONTACT.QUESTIONS}
          </div>
          <div className="f5 black fw3 helvetica">
            {CONTACT.HOURS}
          </div>
          <div className="f5 black bold helvetica pt4">
            {CONTACT.SOUTHERN_COACH}
          </div>
          <div className="f5 black fw3 helvetica pt1">
            {CONTACT.PO_BOX}
          </div>
          <div className="f5 black fw3 helvetica pt1 pb2">
            {CONTACT.CITY_STATE_ZIP}
          </div>
          <a href={`Tel: ${CONTACT.PHONE}`} className="f5 black fw3 helvetica no-underline">
            <i className="fa fa-phone pr2"></i>
            {CONTACT.PHONE}
          </a>
          <div className="flex flex-row">
            <i className="fa fa-envelope pt2"></i>
            <div className="flex flex-column pl2">
              <a href={`mailto: ${CONTACT.EMAIL_SALES}`} className="f5 black fw3 helvetica no-underline pt1">
                {CONTACT.EMAIL_SALES}
              </a>
              <a href={`mailto: ${CONTACT.EMAIL_QUOTES}`} className="f5 black no-underline fw3 helvetica pt1">
                {CONTACT.EMAIL_QUOTES}
              </a>
              <a href={`mailto: ${CONTACT.EMAIL_ACCOUNTING}`} className="f5 no-underline black fw3 helvetica pt1">
                {CONTACT.EMAIL_ACCOUNTING}
              </a>
            </div>
          </div>
        </div>
        <div className={containerClass}>
          <div className="f1 black b pb3" >
            {CONTACT.CONTACT_FORM}
          </div>
          <InputField hasError={!isNameValid} errorMessage="Name is required" onChange={_setName} value={name} placeholder="Name *"/>
          <InputField hasError={!isEmailValid} errorMessage="Email is invalid" onChange={_setEmail} value={email} placeholder="Email address *"/>
          <div className="mt4">
            <InputField hasError={!isSubjectValid} errorMessage="Subject is required" onChange={_setSubject} value={subject} placeholder="Subject *"/>
          </div>
          <MessageInput hasError={!isMessageValid} errorMessage="Message is required" onChange={_setMessage} value={message} placeholder="Message *" />
          <Button onClick={_sendMessage} label="Send message" />
          {(!isNameValid || !isEmailValid || !isSubjectValid || !isMessageValid) && <div class="flex justify-end items-end pt3 helvetica fw3 red">Please fill out the required fields *</div>}
        </div>
      </div>
      {showPopup && <Popup isLoading={isLoading} isSucess={isSucess} isFail={isFail}/>}
    </>
  );
};

    export default React.memo(Contact);
