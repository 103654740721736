import React from 'react';
import { PARTS } from '../constants/text-constants.js';
import { Clients } from '../components';
import { useMediaQuery } from 'react-responsive'

const textStyles = {
  minWidth: 350
};

const partLineStyles = {
  width: '100vw'
};

const Parts = () => {
  const isMobile = useMediaQuery({ maxDeviceWidth: 768 })
  
  return (
    <div className="flex flex-wrap flex-column justify-center items-center">
      <div className={`b flex pb4 justify-center center black ${isMobile ? 'f2 pl3' : 'f1'}`}>{PARTS.WE_HAVE_YOU_COVERED}</div>
      <div className={`helvetica b f4 pb4 mb1 black w-100 ${isMobile ? 'f5 pl3' : 'f4 pl5'}`}>{PARTS.WIDE_RANGE_OF_PARTS}</div>
      {PARTS.PARTS.map((partSection, index) => (
        <div style={partLineStyles} key={index} className={`flex flex-row justify-between align-center flex-wrap helvetica ${isMobile ? 'ph3' : 'ph5'}`}>
          {partSection.map(part => (
            <div style={textStyles} key={part} className={`flex-row flex fw2 helvetica fw3 text-center pb1 dark-blue ${isMobile ? 'f6 mr0 w-100' : 'f5 mr4 w6'}`}>
              <div className="pr3">•</div> {part}
            </div>
          ))}
        </div>
      ))}
      <Clients/>
    </div>
  );
};

export default React.memo(Parts);
