import React from 'react';
import { Palette } from '../styles/styles.js';
import { useMediaQuery } from 'react-responsive'

const Card = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  let { children, isLight } = props;

  const containerStyles = { 
    backgroundColor: isLight ? Palette.white : Palette.dark_blue,
    color: isLight ? Palette.black : Palette.white,
    boxShadow: '4px 8px 36px rgba(0, 0, 0, .25)',
    width: isMobile ? '100%' : '420px',
  }

  return (
    <div className={`dib br1 pv3 ph3 ${props.className}`} style={containerStyles}>
      {children}
    </div>
  );
};

export default Card;
