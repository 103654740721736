export const HOME = {
  SOUTHERN_COACH: 'Southern Coach',
  PARTS_COMPANY: 'Parts Company',
  SOUTHERN_COACH_CARD_BODY_1: 'Providing quality bus parts and',
  SOUTHERN_COACH_CARD_BODY_2: 'excellence across the United States and',
  SOUTHERN_COACH_CARD_BODY_3: 'Puerto Rico since 1946.',
  SCROLL_FOR_MORE: 'Scroll for more info'
};

export const ABOUT = {
  TITLE: 'Southern hospitality and a rich history.',
  BODY_LEFT: 'Southern Coach Manufacturing Company was founded in Evergreen, Alabama on July 1, 1946. The company began by installing metal bodies on buses originally made of wood, and soon began supplying buses for military use. As time passed, Southern Coach began selling transit buses across the nation along with supplying Chattanooga with it’s incline railcar.',
  BODY_RIGHT: 'In 1958, Southern Coach Manufacturing Company began operating in Birmingham, Alabama, under the name of Southern Coach Parts Company. After three owners and over 75 years of business, Southern Coach Parts Company has always held the same principles: to conduct business with integrity and dedication.',
  SERVICES: 'Services',
  SERVICES_BODY: 'Southern Coach Parts not only has the ability and the experience to serve large municipalities across the United States and Puerto Rico, but smaller customers as well. We have built our reputation on our customer service and will continue to make every effort to provide the best service possible.'
};

export const PARTS = {
  WE_HAVE_YOU_COVERED: 'We’ve got you covered.',
  WIDE_RANGE_OF_PARTS: 'Southern Coach Parts Company supplies a wide range of quality replacement parts, including:',
  PARTS: [
    [
      'Albertini Mfg.-AC Compressors',
      'American Seating',
      'Byk-Rak'
    ],
    [
      'Contitech/Goodyear',
      'Cubic Transp.',
      'Cutler-Hammer'
    ],
    [
      'Dynamatic',
      'EMP',
      'Federal Mogul'
    ],
    [
      'Gates',
      'MGM Brakes',
      'Meritor'
    ],
    [
      'Niehoff',
      'Ohio Electric',
      'Power & Signal'
    ],
    [
      'Rosco',
      'SKF-Chicago Rawhide',
      'Sprague Devices'
    ],
    [
      'T&T Transit',
      'Tenneco-Clevite',
      'Thermo King',
    ],
    [
      'Vapor Door Controls',
      'WABTEC',
      'Webb Slack Adjusters',
    ],
    ['Webb Wheel Products']
  ],
  CLIENT_TITLE: 'Southern Coach Parts Company serves a wide range of notable clients, including (but not limited to):',
};

export const CONTACT = {
  QUESTIONS: 'Questions?',
  HOURS: 'Our hours of operation are Monday through Thursday, 8 AM - 4:30 PM CST, and 8 AM - 4 PM CST on Fridays. We are closed on weekends.',
  CONTACT_FORM: 'Contact form',
  SOUTHERN_COACH: 'Southern Coach Parts Co., Inc.',
  PO_BOX: 'PO Box 680144',
  CITY_STATE_ZIP: 'Fort Payne, AL 35968',
  PHONE: '1-800-633-4982',
  EMAIL_SALES: 'sales@southerncoachparts.com',
  EMAIL_QUOTES: 'quotes@southerncoachparts.com',
  EMAIL_ACCOUNTING: 'accounting@southerncoachparts.com',
};
