import React from 'react';
import styled, { keyframes}  from 'styled-components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
`;

const Overlay = styled(Container)`
  max-width: 100vw;
  opacity: 0.4 
`;

const Card = styled.div`
  opacity: 1;
`;

const spin = keyframes`{ 
  100% { 
    -webkit-transform: rotate(-360deg); 
    transform:rotate(-360deg); 
  } 
}
`


export const Popup = props => {
  let { isLoading, isSucess, isFail } = props;
  let message = "Email sent!";
  let subMessage = "We'll get back to you as soon as we can!"


  const LoadingIcon = styled.i`
    transition: all .4s;
    animation: ${isLoading && spin} 2s linear infinite;
  `;

  if (isLoading) {
    message = "sending...";
    subMessage = "";
  };

  if (isFail) {
    message = "Something went wrong!"
    subMessage = "Please try again later";
  };

  return (
    <>
      <Overlay className="bg-black"/>
      <Container className="flex pa3 justify-center items-center">
        <Card className="dib bg-white pv4 ph4 br2 flex justify-center items-center flex-row">
          <div className="flex flex-auto justify-center items-center">
          {isLoading && <LoadingIcon className="fa fa-circle-o-notch f2 black fw3"></LoadingIcon>}
          {isSucess && <LoadingIcon className="fa fa-check f2 black green"></LoadingIcon>}
          {isFail && <LoadingIcon className="fa fa-times f2 black red"></LoadingIcon>}
        </div>
        <div className="flex flex-column pl3 flex-auto">
          <div className="helvetica black fw3 f4">{message}</div>
          {!!subMessage && <div className="helvetica black fw3 f5 pt2">{subMessage}</div>}
        </div>
        </Card>
      </Container>
    </>
  );
};

export default Popup
