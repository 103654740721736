import React, { useState } from 'react';
import styled from 'styled-components';
import { Palette } from '../styles/styles.js';

const Input = styled.textarea`
  box-shadow: 0px 0px 0px;
  display: flex;    
  outline: none;
  border: none;
  resize: none;
  &:focus {
    outline: none;
  };
  &:focus {
    cursor: text;
  }
`;

const InputContainer = styled.div`
  &:hover {
    cursor: text;
  }
`;

export const MessageInput = props => {
  let [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative">
      {(!!props.value || isFocused) && (
        <div 
          className="absolute helvetica fw3 f6 bg-white ph1" 
          style={{left: '12px', top: '-7.5px', lineHeight: '15px'}}
        >
          {props.placeholder}
        </div>
      )}
      <InputContainer className={`ph3 pv2 flex justify-start align-center br2 ba mb3 ${props.hasError ? 'b--red' : 'b--grey'}`}>
        <Input 
          {...props} 
          placeholder={!isFocused ? props.placeholder : undefined} 
          onFocus={() => setIsFocused(true)} 
          onBlur={() => setIsFocused(false)} 
          rows="5" 
          className="helvetica f5 black br2 w-100 fw3 h4"
        />
      </InputContainer>
    </div>
  );
};

export default MessageInput;
