import React from 'react';
import { Home, About, Contact, Parts } from './screens';
import { Nav } from './components';
import styled from 'styled-components';
import { Element } from 'react-scroll'
import './index.css';



const App = () => {
  const AppContainer = styled.div`
    maxWidth: 100vw;
  `;

  return (
    <>
      <AppContainer>
        <Element>
          <Home />
        </Element>
        <About />
        <Parts />
        <Element name="contact">
          <Contact />
        </Element>
      </AppContainer>
      <Nav />
    </>
  );
}

export default App;
