import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as SCPLogo } from '../styles/images/logo.svg';

const NavContainer = styled.div` 
  position: absolute;
  top: 0;
  width: 100vw;
  display: flex;
  z-index: 999;
`;

export const FullNav = props => {
  const { toggleNav } = props;
  const linkClass = "white helvetica fw3 pointer mb4 pt3 pb1  bb"

  return (
    <div 
      onClick={toggleNav} 
      style={{zIndex: 998}} 
      className="flex flex-column items-center justify-start pt5 w-100 h-100 bg-black fixed top-0 left-0 right-0 bottom-0 o-90"
    >
      <Link 
          to="services" 
          className={linkClass}
          onClick={toggleNav}
          smooth={true}
          hashSpy={true}
          duration={500}
          delay={100}
        >
          Services 
        </Link>
        <Link 
          smooth={true}
          onClick={toggleNav}
          hashSpy={true}
          duration={500}
          delay={100}
          to="about"
          className={linkClass}
        >
         Our Story 
        </Link>
        <Link 
          to="contact"
          onClick={toggleNav}
          className={linkClass}
          smooth={true}
          hashSpy={true}
          duration={500}
          delay={100}
        >
         Contact 
        </Link>
    </div>
  );
};

export const Nav = props => {
  let [showNav, setShowNav] = useState(false);
  const isMobile = useMediaQuery({ maxDeviceWidth: 768 })

  const _toggleNav = () => setShowNav(!showNav);

  const linkClass = "black helvetica fw3 pa3 pointer"
  if (isMobile) {
    return (
      <>
        <NavContainer className="w-100 ph3 pa3 justify-between items-center">
          <div className="flex">
            <SCPLogo style={{width: '10vw', height: 'auto', maxHeight: '50px', minWidth: '75px'}}/>
          </div>
          <i className={`fa fa-bars pointer ${showNav ? 'white': 'black'}`} onClick={_toggleNav}></i>
        </NavContainer>
        {showNav && <FullNav toggleNav={_toggleNav}/>}
      </>
      );
  };

  return (
    <NavContainer className="w-100 ph4 pa3 justify-between items-center">
      <div className="flex">
            <SCPLogo style={{width: '10vw', minWidth: '75px'}}/>
      </div>
      <div className="flex">
        <Link 
          to="services" 
          className={linkClass}
          smooth={true}
          hashSpy={true}
          duration={500}
          delay={100}
        >
          Services 
        </Link>
        <Link 
          smooth={true}
          hashSpy={true}
          duration={500}
          delay={100}
          to="about"
          className={linkClass}
        >
         Our Story 
        </Link>
        <Link 
          to="contact"
          className={linkClass}
          smooth={true}
          hashSpy={true}
          duration={500}
          delay={100}
        >
         Contact 
        </Link>
      </div>
    </NavContainer>
  );
};

export default React.memo(Nav);
